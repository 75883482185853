var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "    <li>\n      <a class=\"reaction-nav-item\" href=\"#\" data-reaction=\""
    + alias2(alias1(depth0, depth0))
    + "\">\n        <img src=\"https://static.hudl.com/feedconsumer/images/reaction/"
    + alias2(alias1(depth0, depth0))
    + ".svg\" alt=\""
    + alias2(alias1(depth0, depth0))
    + "\" />\n      </a>\n      <div class=\"triangle-indicator\">\n        <div class=\"top\"></div>\n        <div class=\"bottom\"></div>\n      </div>\n    </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "  <div id=\""
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "-reaction-users-composite\" class=\"reaction-users-composite\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<header class=\"smooth-modal-header\">\n    <h2>People Who Reacted</h2>\n    <div class=\"smooth-modal-close\">\n      <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" version=\"1.1\"><g stroke=\"none\" stroke-width=\"1\" fill-rule=\"evenodd\"><g transform=\"translate(-895.000000, -118.000000)\"><g transform=\"translate(359.000000, 105.000000)\"><g transform=\"translate(536.000000, 13.000000)\"><path d=\"M0 1.5L4.5 6 0 10.5 1.5 12 6 7.5 10.5 12 12 10.5 7.5 6 12 1.5 10.5 0 6 4.5 1.5 0 0 1.5Z\" /></g></g></g></g></svg>\n    </div>\n</header>\n\n<div class=\"smooth-modal-content\">\n  <ul class=\"reaction-nav\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"existingReactions") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":20,"column":13}}})) != null ? stack1 : "")
    + "  </ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"existingReactions") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":2},"end":{"line":24,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});
import React, { PropTypes } from 'react';
import styles from './style.scss';

const PlaceholderText = ({ text, size }) => {
  const containerClassName = `container-${size}`;
  return (
    <div aria-hidden className={styles[containerClassName]}>
      {text.split(/\s/).map((t, i) => {
        return (
          <span key={`${t}-${i}`} className={styles.text}>
            {t}
          </span>
        );
      })}
    </div>
  );
};

PlaceholderText.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['normal', 'large']),
};

PlaceholderText.defaultProps = {
  size: 'normal',
};

export default PlaceholderText;

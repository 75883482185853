import React from 'react';

/* eslint-disable max-len */
const BlankAvatarIcon = () => {
  return (
    <svg width="65px" height="65px" viewBox="0 0 130 130" version="1.1">
        <g id="Timeline" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Desktop-HD-Copy" transform="translate(-182.000000, -215.000000)" strokeWidth="2.5" stroke="#737B83">
                <g id="Base-profile-pic" transform="translate(165.000000, 198.000000)">
                    <g id="Egg-head">
                        <g id="Group-2" transform="translate(18.000000, 18.000000)">
                            <g id="Group-4">
                                <g id="Group-5">
                                    <path d="M63.8402389,127.021559 C98.7343182,127.021559 127.021559,98.7343182 127.021559,63.8402389 C127.021559,28.9461597 98.7343182,0.658919359 63.8402389,0.658919359 C28.9461597,0.658919359 0.658919359,28.9461597 0.658919359,63.8402389 C0.658919359,98.7343182 28.9461597,127.021559 63.8402389,127.021559 Z M63.8419908,26.8735349 C51.7760358,26.8735349 41.6173124,34.3854165 41.7532087,50.4234968 C41.8891049,66.4615771 52.4499797,85.0115392 63.8419914,85.0115392 C75.234003,85.0115392 85.6876201,63.6799676 85.5752934,50.4234968 C85.4629667,37.1670259 75.9079459,26.8735349 63.8419908,26.8735349 Z M106.747797,110.089301 C106.747797,110.089301 106.821417,97.3184499 100.548999,90.0626829 C94.2765817,82.806916 80.8448023,79.3027661 80.8448023,79.3027661 M20.7054288,110.093773 C20.7054288,110.093773 20.6318089,97.3210671 26.9042264,90.0642461 C33.1766439,82.807425 46.6084233,79.3027661 46.6084233,79.3027661" id="Blank-avatar"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  );
};

export default BlankAvatarIcon;

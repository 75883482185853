import React from 'react';
import Waypoint from 'react-waypoint';

import PlayIcon from 'hudl-react-kickoff/icons/Play';
import CommunityContentIdProp from 'common/prop-types/community-content-id';
import VideoRecommendationReason from 'common/video-recommendation-reason';
import PlaceholderText from 'components/shared/placeholder-text';
import g11n from 'hudl-g11n';

const getVideoRecommendationLabel = reasonType => {
  /* eslint-disable */
  switch (reasonType) {
    case VideoRecommendationReason.Popular:
    case VideoRecommendationReason.OverallTrending:
    case VideoRecommendationReason.PersonalizedTrending:
      return g11n.format('profiles.video.recommendations_trending');
    case VideoRecommendationReason.Manual:
      return g11n.format('profiles.video.recommendations_featured');
    case VideoRecommendationReason.UserBased:
    case VideoRecommendationReason.UserBasedHighlights:
    case VideoRecommendationReason.UserBasedHudlOriginals:
      return g11n.format('profiles.video.recommendations_friends_watched');
    case VideoRecommendationReason.ContentBased:
    case VideoRecommendationReason.ContentBasedHighlights:
    case VideoRecommendationReason.ContentBasedHudlOriginals:
      return g11n.format('profiles.video.recommendations_similar');
    default:
      return null;
  }
  /* eslint-enable */
};

class RecommendedVideo extends React.Component {
  static propTypes = {
    badgeUrl: React.PropTypes.string,
    communityContentId: CommunityContentIdProp,
    currentUserId: React.PropTypes.string,
    imageUrl: React.PropTypes.string,
    isPlaceholder: React.PropTypes.bool,
    placeholderTitles: React.PropTypes.shape({
      title: React.PropTypes.string.isRequired,
      subtitle: React.PropTypes.string.isRequired,
      views: React.PropTypes.string.isRequired,
    }),
    onClick: React.PropTypes.func.isRequired,
    onVisible: React.PropTypes.func.isRequired,
    owner: React.PropTypes.string,
    position: React.PropTypes.number,
    reason: React.PropTypes.string,
    subtitle: React.PropTypes.string,
    title: React.PropTypes.string,
    videoUrl: React.PropTypes.string,
    views: React.PropTypes.number,
  };

  static defaultProps = {
    isPlaceholder: false,
  }

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.onVisible = this.onVisible.bind(this);
  }

  handleClick() {
    this.props.onClick(this.props);
  }

  onVisible() {
    if (!this.props.isPlaceholder) {
      this.props.onVisible(this.props);
    }
  }

  getThumbnailContainerBadge() {
    if (!this.props.badgeUrl) {
      return null;
    }

    const divStyle = {
      backgroundImage: `url(${this.props.badgeUrl})`,
    };

    return <div className="thumb-container-badge" style={divStyle} />;
  }
  renderThumbnail() {
    if (this.props.isPlaceholder) {
      return (
        <div className="thumb-container">
          <div className="suggested-video-thumb suggested-video-thumb--placeholder" />
        </div>
      );
    }
    return (
      <div className="thumb-container">
        {this.getThumbnailContainerBadge()}
        <PlayIcon className="svg-play" />
        <img className="suggested-video-thumb" src={this.props.imageUrl} />
      </div>
    );
  }

  renderTitle() {
    if (this.props.isPlaceholder) {
      return <PlaceholderText text={this.props.placeholderTitles.title} size="large" />;
    }
    return <h3 className="suggested-video-title">{this.props.title}</h3>;
  }

  renderSuggestedVideoAthlete() {
    const viewsLabel = g11n.format('profiles.video.recommendations_view_count', {
      views: this.props.views,
      localizedViewCount: this.props.views ? this.props.views.toLocaleString() : '0',
    });

    const suggestionLabel = getVideoRecommendationLabel(this.props.reason);
    if (this.props.isPlaceholder) {
      return (
        <div>
          <PlaceholderText text={this.props.placeholderTitles.subtitle} />
          <PlaceholderText text={this.props.placeholderTitles.views} />
        </div>
      );
    }

    return (
      <h3 className="suggested-video-athlete">
        {this.props.subtitle ? this.props.subtitle : this.props.owner}
        <br />
        <div className="views-label">
          {suggestionLabel && (
            <span className="suggested-video-label">{suggestionLabel}</span>
          )}
          {viewsLabel}
        </div>
      </h3>
    );
  }

  render() {
    return (
      <Waypoint onEnter={this.onVisible} scrollableAncestor={window}>
        <a
          aria-hidden={this.props.isPlaceholder ? true : null}
          className="suggested-video-container"
          href={this.props.videoUrl}
          onClick={this.handleClick}
        >
          {this.renderThumbnail()}
          <div className="suggested-video-info">
            {this.renderTitle()}
            {this.renderSuggestedVideoAthlete()}
          </div>
        </a>
      </Waypoint>
    );
  }
}

export default RecommendedVideo;

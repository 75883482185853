import Marionette from 'backbone.marionette';
import { Service } from 'hudl-base';
import events from '../../../legacy/js/ui/events/events';
import 'hudl-react-kickoff/legacy/ui/events/actionclick';

import AdTrackingUtil from 'utility/ad-tracking-utility';
import CommunityContentPlayers from 'common/container-player';
import CommunityContentContainers from 'common/container-type';

const followService = new Service('follow');
const unfollowService = new Service('unfollow');

const FollowUserView = Marionette.ItemView.extend({
  events: {
    'actionclick .follow-button': '_followClicked',
  },

  _follow: function follow() {
    if (this.model.get('isPending')) {
      return;
    }
    this.model.set('isPending', true);

    followService.post({
      friendId: {
        type: this.model.get('feedUserType'),
        relatedId: this.model.get('feedUserRelatedId'),
      },
      adTrackingId: AdTrackingUtil.getAdTrackingIdAndSetCookie(),
      container: CommunityContentContainers.VideoPage,
      player: CommunityContentPlayers.VideoPage,
      logData: { origin: 'VideoPageTagModal' },
    })
    .done(() => this._isFollowingChanged(true))
    .always(() => this.model.set('isPending', false));
  },

  _unfollow: function unfollow() {
    if (this.model.get('isPending')) {
      return;
    }
    this.model.set('isPending', true);

    unfollowService.post({
      friendId: {
        type: this.model.get('feedUserType'),
        relatedId: this.model.get('feedUserRelatedId'),
      },
      adTrackingId: AdTrackingUtil.getAdTrackingIdAndSetCookie(),
      container: CommunityContentContainers.VideoPage,
      player: CommunityContentPlayers.VideoPage,
      logData: { origin: 'VideoPageTagModal' },
    })
    .done(() => this._isFollowingChanged(false))
    .always(() => this.model.set('isPending', false));
  },

  _followClicked: function _followClicked(e) {
    e.preventDefault();
    if (this.$('.follow-button.following').length) {
      this._unfollow();
    } else {
      this._follow();
    }
  },
  _isFollowingChanged: function _isFollowingChanged(isFollowing) {
    this.$('.follow-button').toggleClass('following', isFollowing);

    events.trigger('update-follow',
      this.model.get('feedUserType'),
      this.model.get('feedUserRelatedId'),
      isFollowing,
    );
  },
});

export default FollowUserView;

export default class TimeSpan {
  constructor(milliseconds) {
    this._milliseconds = +milliseconds || 0;
  }

  valueOf() {
    return this._milliseconds;
  }

  toString() {
    return this.toJSON();
  }

  toJSON() {
    let msRemaining = this._milliseconds;

    const hours = Math.floor(msRemaining / (60 * 60 * 1000));
    msRemaining -= hours * (60 * 60 * 1000);

    const minutes = Math.floor(msRemaining / (60 * 1000));
    msRemaining -= minutes * (60 * 1000);

    const seconds = msRemaining / 1000;

    return [hours, minutes, seconds].join(':');
  }

  seconds() {
    return this._milliseconds / 1000;
  }
}

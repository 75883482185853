import _ from 'underscore';
import FollowUserView from './follow-user-view';
import reactionUserTemplate from '../templates/communitycontent-user.hbs';
import events from '../../../legacy/js/ui/events/events';
import UsageLogEvent from '../utility/usage-log-event';
import 'hudl-react-kickoff/legacy/ui/events/actionclick';
import Marionette from 'backbone.marionette';

const CommunityContentUserView = Marionette.LayoutView.extend({
  regions: {
    followButton: '.follow-user',
  },
  tagName: 'li',
  template: reactionUserTemplate,
  initialize: function initialize() {
    _.bindAll(this,
      'onClickProfile',
    );

    events.on('update-follow', this._updateFollow, this);
  },
  onRender: function onRender() {
    this.$followUser = this.$('.follow-user');
  },
  onBeforeShow: function onBeforeShow() {
    const feedUserId = this.model.get('feedUserId') || {};
    const { type: feedUserType, relatedId: feedUserRelatedId } = feedUserId;
    if (this.$followUser.length && feedUserType && feedUserRelatedId) {
      const followUserView = new FollowUserView({
        el: this.$followUser,
        model: new Backbone.Model({
          feedUserType: feedUserType,
          feedUserRelatedId: feedUserRelatedId,
        }),
      });
      this.getRegion('followButton').attachView(followUserView);
    }
  },
  onDestroy: function onDestroy() {
    events.off('update-follow', this._updateFollow, this);
  },
  triggers: {
    'actionclick .user-avatar': {
      event: 'click:profile',
      preventDefault: false,
      stopPropagation: false,
    },
    'actionclick .username': {
      event: 'click:profile',
      preventDefault: false,
      stopPropagation: false,
    },
  },
  onClickProfile: function onClickProfile() {
    /* eslint-disable no-new */
    new UsageLogEvent({
      logger: 'PublicVideoPage',
      logOnUnload: true,
      data: {
        op: 'Profile',
        func: 'Click',
        origin: 'VideoPageTagModal',
      },
    });
    /* eslint-enable no-new */

    // [paul.graff@hudl.com] 2016-03
    // Marionette is automatically calling preventDefault()
    // and it's unclear how to prevent this behavior. I tried
    // using triggers to solve this, but didn't have any luck.
    window.location = this.$(e.currentTarget).attr('href');
  },
  _updateFollow: function _updateFollow(targetFeedUserType, targetFeedUserRelatedId, isFollowing) {
    if (!(targetFeedUserType && targetFeedUserRelatedId)) {
      return;
    }

    const feedUserId = this.model.get('feedUserId') || {};
    const { type: feedUserType, relatedId: feedUserRelatedId } = feedUserId;
    if (targetFeedUserType !== feedUserType || targetFeedUserRelatedId !== feedUserRelatedId) {
      return;
    }

    this.model.set('isFollowing', isFollowing);
    this.$followUser.find('.friendship').toggleClass('following', isFollowing);
  },
});

export default CommunityContentUserView;

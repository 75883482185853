import { LayoutView } from 'backbone.marionette';
import taggingModalLayoutTemplate from '../templates/tagging-modal-layout.hbs';
import CommunityContentUsersCollection from '../collections/communitycontent-users-collection';
import TaggingUsersCompositeView from './tagging-users-composite-view';
import events from '../../../legacy/js/ui/events/events';
import 'hudl-react-kickoff/legacy/ui/events/actionclick';

const TaggingModalLayoutView = LayoutView.extend({
  className: 'reaction-details-modal',
  template: taggingModalLayoutTemplate,
  initialize: function initialize() {
  },
  onRender: function onRender() {
    this.$reactionUsersWrapper = this.$('.reaction-users-wrapper');
  },
  onBeforeShow: function onBeforeShow() {
    const reactionRegionName = 'reaction-users';
    const reactionRegionId = '#reaction-users-composite';
    this.addRegion(reactionRegionName, reactionRegionId);
    const reactionRegion = this.getRegion(reactionRegionName);
    reactionRegion.show(new TaggingUsersCompositeView({
      model: new Backbone.Model({
        currentUserId: this.model.get('currentUserId'),
      }),
      collection: new CommunityContentUsersCollection(),
    }));
  },
  events: {
    'actionclick .smooth-modal-close': '_closeClicked',
  },
  _closeClicked: function _closeClicked() {
    events.trigger('dialog:close');
  },
});

export default TaggingModalLayoutView;

define(['jquery', 'backbone', 'backbone.marionette', 'underscore', './events/events'],
    function ($, backbone, Marionette, _, events) {

        $(function() {
            var smoothModalOverlay = $('.smooth-modal-overlay');
            if (!smoothModalOverlay.length) {
                $('body').prepend('<div class="smooth-modal-overlay"><div class="smooth-modal"></div></div>');
            }
        });

        var SmoothModal = Marionette.Region.extend({
            el: '.smooth-modal',
            showing: false,
            onShow: function (view) {
                $('html').addClass('smooth-modal-display')
                    .delay(1)
                    .queue(function (next) {
                        $(this).addClass('smooth-modal-slide');
                        next();
                    })
                    .delay(400)
                    .queue(function (next) {
                        $(this).addClass('hide-wrap-and-nav');
                        next();
                    });
            },
            // Not sure that overriding close is a good idea. Probably isn't.
            close: function () {
                var view = this.currentView;
                if (!view || view.isClosed) { return; }

                if (this.showing) {
                    this.disposeView(view);
                    return;
                }
                var thisModal = this;
                $('html').removeClass('hide-wrap-and-nav smooth-modal-slide')
                    .delay(400)
                    .queue(function (next) {
                        $(this).removeClass('smooth-modal-display');
                        thisModal.disposeView(view);
                        next();
                    });
            },
            disposeView: function (view) {
                // call 'close' or 'remove', depending on which is found
                if (view.close) { view.close(); }
                else if (view.remove) { view.remove(); }

                Marionette.triggerMethod.call(this, 'close');

                if (view === this.currentView) {
                    delete this.currentView;
                }
            }
        });

        events.on('dialog:next', function (nextStep) {
            var inner = $('.multi-step-modal-inner');

            $('.step-' + nextStep).show();
            // Makes it top: -100% so it aligns during the slide.
            $('.step-' + nextStep).addClass('right-sliding');

            inner
                .addClass('step-' + nextStep + '-active')
                .removeClass('step-' + (nextStep - 1) + '-active')
                .delay(400)
                .queue(function (next) {
                    $('.step-' + (nextStep - 1)).hide();
                    $('.step-' + nextStep).removeClass('right-sliding');
                    next();
                });


            events.trigger('dialog:next:done');
        });

        events.on('dialog:previous', function (current) {
            var inner = $('.multi-step-modal-inner');

            $('.step-' + (current - 1)).show();
            // Makes it top: -100% so it aligns during the slide.
            $('.step-' + current).addClass('right-sliding');
            inner
                .addClass('step-' + (current - 1) + '-active')
                .removeClass('step-' + current + '-active')
                .delay(400)
                .queue(function (next) {
                    $('.step-' + current).hide();
                    $('.step-' + current).removeClass('right-sliding');
                    next();
                });

            events.trigger('dialog:previous:done');
        });

        return SmoothModal;
    });

// Placeholder recommended videos for rendering the loading state.
export default [
  {
    placeholderTitles: {
      title: '-----------------------',
      subtitle: '-------------------------',
      views: '------------------',
    },
    isPlaceholder: true,
  },
  {
    placeholderTitles: {
      title: '----------- -----------------------',
      subtitle: '------------',
      views: '--------------',
    },
    isPlaceholder: true,
  },
  {
    placeholderTitles: {
      title: '--------------------',
      subtitle: '------------',
      views: '------------------',
    },
    isPlaceholder: true,
  },
];

// Placeholder recommended athletes for rendering the loading state.
export default [
  {
    placeholderTitles: {
      name: '----------------------------',
      subtitles: ['------------------------', '-----------------'],
    },
  },
  {
    placeholderTitles: {
      name: '----------------------------',
      subtitles: ['------------------------', '-----------------'],
    },
  },
  {
    placeholderTitles: {
      name: '----------------------------',
      subtitles: ['------------------------', '-----------------'],
    },
  },
];

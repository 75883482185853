import { View } from 'backbone';
import SharingDialogs from 'utility/sharing-dialogs';

class SharingLinks extends View {
  events() {
    return {
      'click .athlete-info-cta-facebook': '_facebook',
      'click .athlete-info-cta-twitter': '_twitter',
      'click .athlete-info-cta-email': '_email',
    };
  }

  _facebook() {
    SharingDialogs.facebook(
      this.model.get('pageUrl'),
      this.model.get('communityContent')
    );
  }

  _twitter() {
    SharingDialogs.twitter(
      this.model.get('shortenedPageUrl'),
      this.model.get('tweetText'),
      this.model.get('tweetHashTags'),
      this.model.get('communityContent')
    );
  }

  _email() {
    SharingDialogs.email(
      this.model.get('pageUrl'),
      this.model.get('emailSubject'),
      this.model.get('emailBody'),
      this.model.get('communityContent')
    );
  }
}

export default SharingLinks;

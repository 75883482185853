import { bindAll } from 'underscore';
import { LayoutView } from 'backbone.marionette';
import reactionDetailsModalLayoutTemplate from '../templates/reaction-details-modal-layout.hbs';
import CommunityContentUsersCollection from '../collections/communitycontent-users-collection';
import ReactionUsersCompositeView from './reaction-users-composite-view';
import events from '../../../legacy/js/ui/events/events';
import 'hudl-react-kickoff/legacy/ui/events/actionclick';

const ReactionDetailsModalLayoutView = LayoutView.extend({
  className: 'reaction-details-modal',
  template: reactionDetailsModalLayoutTemplate,
  initialize: function initialize() {
    bindAll(this,
      '_reactionNavItemClicked',
      '_selectReaction',
    );
  },
  onRender: function onRender() {
    this.$reactionNav = this.$('.reaction-nav');
    this.$reactionUsersWrapper = this.$('.reaction-users-wrapper');
  },
  onBeforeShow: function onBeforeShow() {
    const existingReactions = this.model.get('existingReactions') || [];

    existingReactions.forEach(reaction => {
      const reactionRegionName = this._generateRegionName(reaction);
      const reactionRegionId = `#${reactionRegionName}-composite`;
      this.addRegion(reactionRegionName, reactionRegionId);
      const reactionRegion = this.getRegion(reactionRegionName);
      reactionRegion.show(new ReactionUsersCompositeView({
        model: new Backbone.Model({
          currentUserId: this.model.get('currentUserId'),
          reaction,
        }),
        collection: new CommunityContentUsersCollection(),
      }));

      // Hide the region by default
      reactionRegion.$el.hide();
    });

    if (existingReactions.length) {
      this._selectReaction(existingReactions[0]);
    }
  },
  events: {
    'actionclick .smooth-modal-close': '_closeClicked',
    'actionclick .reaction-nav .reaction-nav-item': '_reactionNavItemClicked',
  },
  _closeClicked: function _closeClicked() {
    events.trigger('dialog:close');
  },
  _generateRegionName: function _generateRegionName(reaction) {
    return `${reaction}-reaction-users`;
  },
  _reactionNavItemClicked: function _reactionNavItemClicked(e) {
    const $reaction = $(e.currentTarget);
    const reaction = $reaction.data('reaction');
    this._selectReaction(reaction);
  },
  _selectReaction: function _selectReaction(nextSelectedReaction) {
    const previousSelectedReaction = this.model.get('selectedReaction');

    // Skip reloading reaction user data if the same reaction is selected twice
    if (previousSelectedReaction === nextSelectedReaction) {
      return;
    }

    this.$reactionNav.children('li')
      .removeClass('selected')
      .find(`.reaction-nav-item[data-reaction='${nextSelectedReaction}']`)
      .parent()
      .addClass('selected');

    // Hide the reaction user section for the previous selected reaction
    const previousSelectedReactionRegionName = this._generateRegionName(previousSelectedReaction);
    const previousSelectedReactionRegion = this.getRegion(previousSelectedReactionRegionName);
    if (previousSelectedReactionRegion) {
      previousSelectedReactionRegion.$el.hide();
    }

    // Show the reaction user section for the next selected reaction
    const nextSelectedReactionRegionName = this._generateRegionName(nextSelectedReaction);
    const nextSelectedReactionRegion = this.getRegion(nextSelectedReactionRegionName);
    if (nextSelectedReactionRegion) {
      nextSelectedReactionRegion.$el.show();
    }

    this.model.set('selectedReaction', nextSelectedReaction);
  },
});

export default ReactionDetailsModalLayoutView;

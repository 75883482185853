/**
 * 2016-3-23 [paul.graff@hudl.com]
 * Temporary port of UsageLogEvent from github.com/hudl/js-hudl-base
 * hudl-base is missing timespan.js, so it's breaking when we rely on the window.unload event
 * for UsageLogging. I'm submitting a PR for js-hudl-base to address this.
 */

/*eslint-disable */
import $ from 'jquery';
import _  from 'underscore';
import { Logger } from 'hudl-base';
import TimeSpan from './timespan';

var eventsToLogOnUnload = [];

// On page unload, we want to stop and log any events that are currently being timed.
// Using the logger's static method instead of tracking the events separately to ensure proper timing
Logger.onBeforeUnload(function () {
  _.invoke(eventsToLogOnUnload, 'stop', { pageUnloaded: true });
});

/// <summary>
/// Helps track events for usage logging, optionally providing timer features.
/// </summary>
export default function UsageLogEvent (options) {
  this.options = _.defaults(options || {}, {
    // the name of the current client "logger"
    logger: 'ClientUsage',
    // name of the event being tracked, preferrably with no spaces (for Splunk-friendliness)
    eventName: null,
    // additional data to be logged with the event; this can be extended via .addData() or by passing data to .stop()
    data: {},
    // called immediately before a log is prepared and queued
    onBeforeLog: $.noop,
    // determines if the event should be stopped and logged when the page is unloaded (tab closed, refreshed, or non-push-state page navigation)
    logOnUnload: true,
    // defines a view to automatically add start and stop events for show/close events
    attachToView: null,
    // format the timer in seconds instead of hh:mm:ss
    formatSeconds: false,
    // detect if the user is idle and remove any idle time from the Timer
    detectIdleTime: false
  });

  if (options.logOnUnload) {
    eventsToLogOnUnload.push(this);
  }

  if (options.detectIdleTime) {
    var t = this;
    this._idleTime = 0;
    this._totalIdleTime = 0;
    this._isWindowFocused = true;
    this._idleInterval = setInterval(function () {
      t._idleTime += 1;
      if (t._idleTime > 29 || !t._isWindowFocused) {
        t._totalIdleTime += 1000;
      }
    }, 1000);

    var resetIdleTime = function () {
      t._idleTime = 0;
    };
    $(document).on('mousemove keypress', resetIdleTime);
    $(window).on('blur', function () { t._isWindowFocused = false; });
    $(window).on('focus', function () { t._isWindowFocused = true; });
  }

  var attachToView = options.attachToView;
  if (attachToView) {
    var onClose = function () {
      this.stop({ viewClosed: true });
    };
    attachToView.on({
      'show': this.start,
      'close': onClose
    }, this);

    this._unbindFromView = function () {
      attachToView.off({
        'show': this.start,
        'close': onClose
      }, this);
    };
  }
}

_.extend(UsageLogEvent.prototype, {
  start: function () {
    if (this._isLogged) {
      return this;
    }

    this._startTime = new Date();
    return this;
  },

  stop: function (data, options) {
    if (this._isLogged) {
      return this;
    }

    options = options || {};

    this.addData(data);

    var thisTimer = new TimeSpan(new Date() - this._startTime - (this._totalIdleTime || 0));
    if (this.options.formatSeconds) {
      this.addData({ timer: thisTimer.seconds() });
    } else {
      this.addData({ timer: thisTimer });
    }
    if (this._idleInterval) {
      clearInterval(this._idleInterval);
    }

    if (options.log !== false) {
      this._sendLog();
    }

    return this;
  },

  addData: function (data) {
    if (this._isLogged) {
      return this;
    }

    _.extend(this.options.data, data || {});
    return this;
  },

  // log an untimed event
  log: function (data) {
    if (this._isLogged) {
      return;
    }

    this.addData(data);
    this._sendLog();
  },

  // make the server log request
  _sendLog: function () {
    // only allow this to happen once
    if (this._isLogged) {
      return;
    }

    var options = this.options;

    if (options.onBeforeLog) {
      options.onBeforeLog.call(this);
    }

    // doing an extend here so we can put the event name "first" in the log
    var logData = _.extend({ 'event': options.eventName }, options.data);

    new Logger(options.logger).log('', logData);

    // clean up potential references
    eventsToLogOnUnload = _.without(eventsToLogOnUnload, this);
    if (this._unbindFromView) { this._unbindFromView(); }
    delete this.options;

    this._isLogged = true;
  }
});

import {errorReporter} from 'hudl-base';
window.onerror = errorReporter.sendError;

import 'babel-polyfill';
import $ from 'jquery';
import 'hudl-react-kickoff/legacy/lib/kickoff/layout-main';
import React from 'react';
import { Model } from 'backbone';
import { Application as MarionetteApplication } from 'backbone.marionette';
import SmoothModal from '../../legacy/js/ui/smooth-modal';
import { data, Service, UsageLogEvent } from 'hudl-base';
import events from '../../legacy/js/ui/events/events';
import FeedUserType from 'common/feed-user-type';
import GoogleAdRefresher from '../../utility/google-ad-refresher';
import Logger from 'utility/community-content-logger';
import { toCommunityContentIdString } from 'common/community-content-id';
import ReactionDetailsModalLayoutView from './views/reaction-details-modal-layout-view';
import TaggingModalLayoutView from './views/tagging-modal-layout-view';
import Recommendations from 'components/video-page/recommendations/recommendations';
import SharingLinks from './views/sharing-links';
import UnloadUtility from 'utility/unload-utility';
import trackPixels from 'utility/pixel-tracker';
import AdTrackingUtil from 'utility/ad-tracking-utility';
import CommunityContentContainers from 'common/container-type';
import CommunityContentPlayers from 'common/container-player';
import { render } from 'react-dom';
import loadAsyncScripts from 'common/async-scripts';
import 'whatwg-fetch';
import './video-page.scss';
import dependencyLoader from '../../utility/dependency-loader';
import DisplayAd from '../../components/shared/display-ad';
import ads from '../../utility/ads';
import DisplayAdModel from '../../utility/ads/display-ad-model';
import { MediaSnowplowManager } from '../../utility/page-tracking-utility';
import browserDetect from '../../utility/browser-detect';
import promoteDynamicContent from 'utility/comscore';

function setupProfileClickLogging(pageData) {
  $('.js-profile-link').click(() => {
    const feedUserId = pageData.owner.feedUserType + '-' + pageData.owner.id;
    const userType = pageData.owner.feedUserType;

    let teamId;
    let userId;
    if (userType === FeedUserType.User) {
      userId = pageData.owner.id;
    } else if (userType === FeedUserType.Team) {
      teamId = pageData.owner.id;
    }

    new UsageLogEvent({
      logger: 'PublicVideoPage',
      data: {
        op: 'Profile',
        func: 'Click',
        origin: 'VideoPage',
        feedUser: feedUserId,
        ownerType: pageData.owner.ownerType,
        user: userId || -1,
        team: teamId || -1,
      },
    }).log();
  });
}

const acknowledgedVideoImpressions = {};
let pendingVideoImpressions = [];
let pendingVideoImpressionsLoggerTimer = null;

const logPendingVideoImpressions = () => {
  if (pendingVideoImpressionsLoggerTimer !== null) {
    return;
  }

  pendingVideoImpressionsLoggerTimer = setTimeout(() => {
    pendingVideoImpressionsLoggerTimer = null;

    const videoImpressions = pendingVideoImpressions;
    pendingVideoImpressions = [];

    const impressionList = videoImpressions
      .map(c => acknowledgedVideoImpressions[c])
      .filter(v => v !== null && !v.isPlaceholder && v.communityContentId)
      .map(v => ({
        communityContentId: v.communityContentId,
        isRecommendation: true,
        suggestionReason: v.reason,
      }));

    if (!impressionList.length) {
      return;
    }

    Logger.impressionList(impressionList);
  }, 1000);
};

const logImpressionForVideo = function logImpressionForVideo(video) {
  const communityContentIdString = toCommunityContentIdString(video.communityContentId);

  // We want to avoid logging multiple impressions for the same video.
  if (communityContentIdString in acknowledgedVideoImpressions) {
    return;
  }
  acknowledgedVideoImpressions[communityContentIdString] = video;
  pendingVideoImpressions.push(communityContentIdString);

  logPendingVideoImpressions();
};

function setupRecommendationLogging(getPlayer) {
  getPlayer().on('post-roll:recommendation-visible', logImpressionForVideo);
}

function setupRecommendations(pageData, sessionData) {
  UnloadUtility.instance.attachHandler(() => {
    if (pendingVideoImpressionsLoggerTimer) {
      clearTimeout(pendingVideoImpressionsLoggerTimer);
    }
  });

  const rawData = data.raw();
  const { userId: currentUserId } = rawData.hudl || {};
  const showAthleteRecommendations = pageData.owner.feedUserType !== FeedUserType.Hudl;

  render(
    <Recommendations
      currentUserId={currentUserId}
      includeAthletes={showAthleteRecommendations}
      onRecommendedVideoVisible={logImpressionForVideo}
      pageData={pageData}
      sessionData={sessionData}
      />,
    document.getElementById('suggestions-container')
  );
}

function setupFollowing(pageData) {
  const followButton = $('.follow-button');
  followButton.click((e) => {
    e.preventDefault();

    if (followButton.hasClass('disabled')) {
      return;
    }
    followButton.addClass('disabled');

    followButton.toggleClass('following');
    if (!pageData.isFollowing) {
      new Service('follow').post({
        friendId: { type: pageData.owner.feedUserType, relatedId: pageData.owner.id },
        logData: { Origin: 'VideoPage' },
        adTrackingId: AdTrackingUtil.getAdTrackingIdAndSetCookie(),
        container: CommunityContentContainers.VideoPage,
        player: CommunityContentPlayers.VideoPage,
      }).done(() => {
        followButton.removeClass('disabled');
      });
      pageData.isFollowing = true;
    } else {
      new Service('unfollow').post({
        friendId: { type: pageData.owner.feedUserType, relatedId: pageData.owner.id },
        logData: { Origin: 'VideoPage' },
        adTrackingId: AdTrackingUtil.getAdTrackingIdAndSetCookie(),
        container: CommunityContentContainers.VideoPage,
        player: CommunityContentPlayers.VideoPage,
      }).done(() => {
        followButton.removeClass('disabled');
      });
      pageData.isFollowing = false;
    }
  });
}

function setupTagging() {
  const $taggingContainer = $('.tagging-container');

  const TaggingModalApp = new MarionetteApplication();
  TaggingModalApp.addRegions({
    dialogRegion: SmoothModal,
  });

  const onCloseDialog = () => {
    TaggingModalApp.dialogRegion.close();
    TaggingModalApp.dialogRegion.reset();
  };

  $('.smooth-modal-overlay').on('click', function onClickSmoothModalOverlay(e) {
    const smoothModal = $(e.target).closest('.smooth-modal');
    if (!smoothModal.length) {
      onCloseDialog();
    }
  });

  events.on('dialog:close', onCloseDialog);

  $taggingContainer.find('.js-profile-tag-link')
    .on('click touchend', function clickTaggedUser() {
      new UsageLogEvent({
        logger: 'PublicVideoPage',
        data: {
          op: 'Profile',
          func: 'Click',
          origin: 'VideoPageTags',
        },
      }).log();
    });

  $taggingContainer.find('.js-others-tag-link')
    .on('click touchend', function clickReactionDetailsPreview(e) {
      e.stopPropagation();
      e.preventDefault();
      new UsageLogEvent({
        logger: 'PublicVideoPage',
        data: {
          op: 'AllTags',
          func: 'Click',
          origin: 'VideoPage',
        },
      }).log();

      const rawData = data.raw();
      const { userId: currentUserId } = rawData.hudl || {};
      const taggingModalLayoutView = new TaggingModalLayoutView({
        model: new Model({
          currentUserId,
        }),
      });
      TaggingModalApp.dialogRegion.show(taggingModalLayoutView);
    });
}

function setupReactions(pageData) {
  const $reactionContainer = $('.reaction-container');

  const $addReactionPopup = $reactionContainer.find('.add-reaction-popup');
  const $reactionDetails = $reactionContainer.find('.reaction-details');
  const $reactionList = $reactionContainer.find('.reaction-list');

  // We need to keep track of *which* preview message indicates the current user has reacted
  let $reactionDetailsPreview;
  let $hasReactedReactionDetailsPreview;

  const initialCurrentUserInteractionCount =  $reactionDetails.data('currentUserInteractionCount');
  if (initialCurrentUserInteractionCount <= 0) {
    $reactionDetailsPreview = $reactionDetails.find('.reaction-details-preview');
    $hasReactedReactionDetailsPreview = $reactionDetails.find('.reaction-details-preview-alternate');
  } else {
    $reactionDetailsPreview = $reactionDetails.find('.reaction-details-preview-alternate');
    $hasReactedReactionDetailsPreview = $reactionDetails.find('.reaction-details-preview');
  }

  function animateRemovingReaction(reaction) {
    reaction.fadeTo(250, 0, function reactionFadeOut() { reaction.hide(); reaction.css('opacity', '1'); });
    const nextReaction = reaction.parent().next('li');
    if (nextReaction) {
      nextReaction.animate({marginLeft: '-51px'}, 250, function nextReactionAnimate() {
        nextReaction.css('margin-left', '1px');
      });
    }
  }

  const getExistingReactionsWithCounts = () => {
    const existingReactionsWithCounts = [];
    $reactionList.find('.reaction')
      .each((index, reactionEl) => {
        const $reaction = $(reactionEl);
        const reaction = $reaction.data('reaction');
        const $reactionCount = $reaction.find('.reaction-count span');
        let count = 0;
        if ($reactionCount.length) {
          count = parseInt($reactionCount.text(), 10);
        }

        if (count > 0) {
          existingReactionsWithCounts.push({
            count,
            reaction,
          });
        }
      });
    return existingReactionsWithCounts;
  };

  const hideReactionPopupClickHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();

    $addReactionPopup.hide();
  };

  function triggerReflow(reaction) {
    reaction.removeClass('animate');
  }

  const updateReactionDetailsPreviewMessage = () => {
    if (!$reactionDetails) {
      return;
    }

    const currentUserInteractionCount = $reactionList.find('.reaction.reacted').length;

    // First, determine which reaction preview message to display
    if (currentUserInteractionCount) {
      if ($reactionDetailsPreview.length) {
        $reactionDetailsPreview.hide();
      }
      $hasReactedReactionDetailsPreview.show();
    } else {
      $hasReactedReactionDetailsPreview.hide();
      if ($reactionDetailsPreview.length) {
        $reactionDetailsPreview.show();
      }
    }

    // Next, determine if we need to hide the reaction details preview updateReactionDetailsPreviewMessage
    const existingReactionsWithCounts = getExistingReactionsWithCounts();
    const totalReactionCount = existingReactionsWithCounts.reduce((prevReactionCount, existingReactionData) => {
      return prevReactionCount + (existingReactionData.count || 0);
    }, 0);

    if (totalReactionCount <= 0) {
      $reactionDetails.hide();
    } else {
      $reactionDetails.show();
    }
  };

  $reactionContainer.find('.add-reaction-btn').on('click touchend', function clickAddReactionButton(e) {
    e.stopPropagation();
    e.preventDefault();

    // If the add reaction popup is already visible, hide it.
    if ($addReactionPopup.is(':visible')) {
      $addReactionPopup.hide();
      return;
    }

    $(document).one('click touchend', hideReactionPopupClickHandler);

    $addReactionPopup.show();
  });

  $reactionContainer.find('.reaction, .available-reaction').on('click touchend', function clickReaction(e) {
    e.stopPropagation();
    e.preventDefault();

    const $reaction = $(this);

    if ($reaction.hasClass('available-reaction') && $reaction.hasClass('reacted')) {
      return;
    }

    const reaction = $reaction.data('reaction');
    const $availableReaction = $reactionContainer.find('.available-reaction.reaction-' + reaction);
    const $reactionIcon = $reactionContainer.find('.reaction.reaction-' + reaction);

    $addReactionPopup.hide();

    const $countHolder = $reactionIcon.children('.reaction-count').children('span');

    if ($reactionIcon.hasClass('reacted')) {
      new Service('toggleReaction', {reaction: reaction}).delete({
        secondaryRelatedId: pageData.owner.id,
        adTrackingId: AdTrackingUtil.getAdTrackingIdAndSetCookie(),
        container: CommunityContentContainers.VideoPage,
        player: CommunityContentPlayers.VideoPage,
        logdata: {
          Origin: 'VideoPage',
        },
      });
      $reactionIcon.removeClass('reacted');
      $availableReaction.removeClass('reacted');
      if ($countHolder) {
        const count = parseInt($countHolder.text(), 10) - 1;
        $countHolder.text(count);
        if (count === 0) {
          animateRemovingReaction($reactionIcon);
        }
      }
    } else {
      Logger.reaction(data.get('communityContent'), reaction);
      new Service('toggleReaction', {reaction: reaction}).post({
        secondaryRelatedId: pageData.owner.id,
        adTrackingId: AdTrackingUtil.getAdTrackingIdAndSetCookie(),
        container: CommunityContentContainers.VideoPage,
        player: CommunityContentPlayers.VideoPage,
        logdata: {
          Origin: 'VideoPage',
        },
      });
      $reactionIcon.addClass('reacted');
      $availableReaction.addClass('reacted');
      if ($countHolder) {
        const count = parseInt($countHolder.text(), 10) + 1;
        $countHolder.text(count);
      }
      $reactionIcon.show();
      $reactionIcon.addClass('animate');
      setTimeout(triggerReflow, 1000, $reactionIcon);

      const onReactionTrackingPixels = data.getNullable('onReactionTrackingPixels');
      trackPixels(onReactionTrackingPixels);
    }

    // Update the reaction details preview message after adding or removing a reaction
    updateReactionDetailsPreviewMessage();
  });

  const reactionDetailsModalApp = new MarionetteApplication();
  reactionDetailsModalApp.addRegions({
    dialogRegion: SmoothModal,
  });

  const onCloseDialog = () => {
    reactionDetailsModalApp.dialogRegion.close();
    reactionDetailsModalApp.dialogRegion.reset();
  };

  $('.smooth-modal-overlay').on('click', function onClickSmoothModalOverlay(e) {
    const smoothModal = $(e.target).closest('.smooth-modal');
    if (!smoothModal.length) {
      onCloseDialog();
    }
  });

  events.on('dialog:close', onCloseDialog);
  $reactionContainer.find('.reaction-details-preview, .reaction-details-preview-alternate')
    .on('click touchend', function clickReactionDetailsPreview(e) {
      e.stopPropagation();
      e.preventDefault();

      new UsageLogEvent({
        logger: 'PublicVideoPage',
        data: {
          op: 'WhoReacted',
          func: 'Click',
          origin: 'VideoPage',
        },
      }).log();

      const existingReactionsWithCounts = getExistingReactionsWithCounts();
      const sortedExistingReactions = existingReactionsWithCounts.sort((a, b) => {
        const aCount = a.count || 0;
        const bCount = b.count || 0;
        return bCount - aCount;
      }).map(({ reaction }) => reaction);

      const rawData = data.raw();
      const { userId: currentUserId } = rawData.hudl || {};
      const reactionDetailsModalLayoutView = new ReactionDetailsModalLayoutView({
        model: new Model({
          currentUserId,
          existingReactions: sortedExistingReactions,
        }),
      });
      reactionDetailsModalApp.dialogRegion.show(reactionDetailsModalLayoutView);
    });
}

function setupDescriptionToggle() {
  // find each description element
  $('.video-information-container .highlight-info-description[data-alternate-text]').each((i, element) => {
    element.addEventListener('click', () => {
      const description = element.childNodes[0].textContent.trim();
      const alternateDescription = element.getAttribute('data-alternate-text');

      // switch rendered and alternate text
      element.setAttribute('data-alternate-text', description);
      element.childNodes[0].textContent = alternateDescription;

      // toggle class
      const classes = element.className.split(' ');
      const classIndex = classes.indexOf('highlight-info-description--truncated');

      if (classIndex === -1) {
        classes.push('highlight-info-description--truncated');
      } else {
        classes.splice(classIndex, 1);
      }

      element.className = classes.join(' ');
    });
  });
}

function setupSharingLinks(pageData, getPlayer) {
  const sharingModel = new Model(pageData.videoSharingInfo);
  sharingModel.set({
    ownerType: pageData.owner.ownerType,
    communityContent: data.get('communityContent'),
    getPlayer: getPlayer,
  });

  new SharingLinks({ // eslint-disable-line no-new
    el: '.athlete-info-cta',
    model: sharingModel,
  });
}

function setupAds() {
  const isMobile = data.get('isMobile') || browserDetect.isModernIPad;
  const sizes = isMobile
    ? [ads.sizes.MobileLeaderboard]
    : [ads.sizes.MediumRectangle, ads.sizes.Leaderboard, ads.sizes.MobileLeaderboard];

  const mappings = isMobile
    ? [ads.mappings.mobileLeaderboard, ads.mappings.leaderboard]
    : ads.normalizeSizeMappings([ads.mappings.leaderboard, ads.mappings.mobileLeaderboard, ads.mappings.mediumRectangle]);

  const displayAdId = isMobile ? 'div-gpt-ad-video-page-mobile-ad-id' : 'div-gpt-ad-video-page-desktop-ad-id';
  const displayAdContainer = isMobile ? 'mobile-ad-container' : 'ad-container';
  const displayAdModel = new DisplayAdModel(displayAdId, 'Display_VideoPageTest');
  document.getElementById(isMobile ? 'ad-container' : 'mobile-ad-container').style.display = 'none';

  render(
    <DisplayAd
      id={displayAdModel.getId()}
      isMobile={isMobile}
      path={displayAdModel.getPath()}
      sizes={sizes}
      adSizeMapping={mappings}
      disableAdWrapper={!isMobile}
      disableAdBlockCheck={!isMobile}
    />, document.getElementById(displayAdContainer));
}

function setupRestOfVideoPage(pageData, sessionData, hudlPlayer) {
  setupAds();
  setupRecommendations(pageData, sessionData);
  setupSharingLinks(pageData, hudlPlayer);
  setupProfileClickLogging(pageData);
  setupFollowing(pageData);
  setupReactions(pageData);
  setupTagging();
  setupDescriptionToggle();
  setupRecommendationLogging(hudlPlayer);

  new GoogleAdRefresher([400, 700, 1000, 1055]); // eslint-disable-line no-new
}

$(() => {
  dependencyLoader(2, context => {
    window.__hudlui_Generic && window.__hudlui_Generic.init();
    const hudlUiWebNavPlugin = window.__hudlui_WebNav;
    if (hudlUiWebNavPlugin) {
      hudlUiWebNavPlugin.renderHelper();
    }

    promoteDynamicContent(context.pageData.comScoreId);
    setupRestOfVideoPage(context.pageData, context.sessionData, context.hudlPlayer);
    MediaSnowplowManager.initialize(context.pageData.mediaSnowplowTrackerContext)
    MediaSnowplowManager.trackPagePings();
    MediaSnowplowManager.trackPage('VideoPage');
    return context;
  });

  loadAsyncScripts();
});

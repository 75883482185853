import _ from 'underscore';
import Marionette from 'backbone.marionette';
import { Service } from 'hudl-base';
import 'hudl-react-kickoff/legacy/ui/events/actionclick';
import CommunityContentUserModel from '../models/communitycontent-user-model';
import CommunityContentUserView from './communitycontent-user-view';
import FeedUserType from 'common/feed-user-type';
import reactionUsersCompositeTemplate from '../templates/communitycontent-users-composite.hbs';

const ajaxGetOptions = {
  cache: false,
};

const interactionService = new Service('interactions');

const ReactionUsersCompositeView = Marionette.CompositeView.extend({
  childView: CommunityContentUserView,
  childViewContainer: '.reaction-users',
  className: 'reaction-users-wrapper',
  template: reactionUsersCompositeTemplate,
  initialize: function initialize() {
    _.bindAll(this,
      '_loadUsers',
    );
  },
  onRender: function onRender() {
    this.$reactionProgressSpinnnerWrapper = this.$('.infinite-css-spinner-wrapper');

    this._loadUsers();
  },
  onShow: function onShow() {
    this.$el.on('scroll', _.throttle(() => this._onScroll()));
  },
  _onScroll: function _onScroll() {
    const scrollTop = this.$el.scrollTop();
    const maxScroll = this.$el.get(0).scrollHeight - this.$el.height();
    const scrollPercentage = (scrollTop / maxScroll);
    if (scrollPercentage > 0.80) {
      this._loadUsers();
    }
  },
  _loadUsers: function _loadUsers() {
    if (this.model.get('isPending') || this.model.get('isComplete')) {
      return;
    }

    this.model.set('isPending', true);
    this._updateDisplayProgress();

    const reaction = this.model.get('reaction');
    const requestData = {
      reaction,
    };

    const firstReactionDate = this.model.get('firstReactionDate');
    if (firstReactionDate) {
      requestData.beforeDateTime = firstReactionDate;
    }

    interactionService.get(requestData, ajaxGetOptions)
      .fail(() => {
        // TODO: Error handling
      })
      .done((result, status) => {
        if (status === 'error') {
          // TODO: Error handling
          return;
        }

        const { firstReactionDate: updatedFirstReactionDate, users = [] } = result;

        if (!users.length) {
          this.model.set('isComplete', true);
        }

        // Update the first reaction date for the reaction
        this.model.set('firstReactionDate', updatedFirstReactionDate);

        const currentUserId = this.model.get('currentUserId');
        const reactionUsers = users.map(user => {
          if (currentUserId !== user.userId) {
            user.displayFollowButton = true;
          }
          if (user.feedUserId.type === FeedUserType.User) {
            user.url = '/profile/' + user.feedUserId.relatedId;
          }
          return new CommunityContentUserModel(user);
        });

        this.collection.add(reactionUsers);
        this.render();
      })
      .always(() => {
        this.model.set('isPending', false);
        this._updateDisplayProgress();
      });
  },
  _updateDisplayProgress: function _updateDisplayProgress() {
    if (this.model.get('isPending')) {
      this.$reactionProgressSpinnnerWrapper.show();
    } else {
      this.$reactionProgressSpinnnerWrapper.hide();
    }
  },
});

export default ReactionUsersCompositeView;

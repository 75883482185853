import React from 'react';
import ArrowRightIcon from 'hudl-react-kickoff/icons/Arrowright';
import BlankAvatarIcon from 'components/shared/blank-avatar-icon';
import PlaceholderText from 'components/shared/placeholder-text';
import classNames from 'classnames';

class RecommendedAthlete extends React.Component {
  static propTypes = {
    onClick: React.PropTypes.func,
    name: React.PropTypes.string,
    imageUrl: React.PropTypes.string,
    isPlaceholder: React.PropTypes.bool,
    placeholderTitles: React.PropTypes.shape({
      name: React.PropTypes.string.isRequired,
      subtitles: React.PropTypes.arrayOf(React.PropTypes.string),
    }),
    url: React.PropTypes.string,
    positions: React.PropTypes.string,
    school: React.PropTypes.string,
    views: React.PropTypes.number,
  };

  static defaultProps = {
    isPlaceholder: false,
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.onClick(this.props);
  }

  renderInfo() {
    const spacer = this.props.positions ? ' | ' : null;
    const views = this.props.views ? this.props.views.toLocaleString() : '0';
    if (this.props.isPlaceholder) {
      return (
        <div className="athlete-holder">
          <PlaceholderText text={this.props.placeholderTitles.name} />
          {this.props.placeholderTitles.subtitles.map((text, i) => (
            <PlaceholderText key={`${text}-${i}`} text={text} />
          ))}
        </div>
      );
    }
    return (
      <div className="athlete-holder">
        <h2 className="suggested-athlete-name">{this.props.name}</h2>
        <h3 className="suggested-athlete-info">
          {this.props.positions}
          {spacer}
          {views} Views
        </h3>
        <h4 className="suggested-athlete-school">{this.props.school}</h4>
      </div>
    );
  }

  render() {
    const { isPlaceholder, imageUrl } = this.props;
    const backgroundImageUrl = {
      backgroundImage: `url('${imageUrl}')`,
    };
    const backgroundImage = imageUrl ? backgroundImageUrl : {};

    const imageClassName = classNames('photo-holder', {
      'photo-holder--no-img': !isPlaceholder && !imageUrl,
      'photo-holder--placeholder': isPlaceholder,
    });
    return (
      <a
        className="related-athlete-lnk"
        href={this.props.url}
        onClick={this.handleClick}
      >
        <div className="suggested-athlete-container">
          <div className={imageClassName} style={backgroundImage}>
            {!isPlaceholder && !imageUrl ? <BlankAvatarIcon /> : null}
            {!isPlaceholder && <ArrowRightIcon className="svg-arrow" />}
          </div>
          {this.renderInfo()}
        </div>
      </a>
    );
  }
}

export default RecommendedAthlete;

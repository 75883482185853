import Backbone from 'backbone';

const CommunityContentUserModel = Backbone.Model.extend({
  initialize: function initialize() {
    this.set('positionString', this._getPositionString());
    this.set('schoolInfo', this._getSchoolInfo());
  },
  _getPositionString: function _getPositionString() {
    const positions = this.get('positions') || [];
    return positions.join(', ');
  },
  _getSchoolInfo: function _getSchoolInfo() {
    const schoolCity = this.get('schoolCity');
    const schoolState = this.get('schoolState');
    const schoolName = this.get('schoolName');

    let schoolLocation = schoolCity || schoolState;
    if (schoolCity && schoolState) {
      schoolLocation = `${schoolCity}, ${schoolState}`;
    }

    if (schoolName && schoolLocation) {
      return `${schoolName} &bull; ${schoolLocation}`;
    }

    return schoolName || schoolLocation;
  },
});

export default CommunityContentUserModel;

import React from 'react';
import RecommendedAthlete from './recommended-athlete';
import PlaceholderAthletes from 'common/placeholders/recommended-athletes';
import ReactCSSTransitionReplace from 'react-css-transition-replace';

class RecommendedAthletes extends React.Component {
  static propTypes = {
    athletes: React.PropTypes.array,
    isPlaceholder: React.PropTypes.bool.isRequired,
    onClick: React.PropTypes.func.isRequired,
  };

  renderPlaceholderAthletes(athletes) {
    return (
      <div>
        {athletes.map((athlete, index) => {
          return (
            <RecommendedAthlete
              key={`placeholder-${index}`}
              position={index}
              onClick={this.props.onClick}
              isPlaceholder
              placeholderTitles={athlete.placeholderTitles}
            />
          );
        })}
      </div>
    );
  }

  getAthletes() {
    return this.props.isPlaceholder ? PlaceholderAthletes : this.props.athletes;
  }

  renderAthletes(athletes) {
    const { isPlaceholder } = this.props;
    if (isPlaceholder) {
      return this.renderPlaceholderAthletes(athletes);
    }
    if (!athletes || !athletes.length) {
      return null;
    }
    return athletes.map((athlete, index) => {
      return (
        <RecommendedAthlete
          key={athlete.id}
          position={index + 1}
          onClick={this.props.onClick}
          {...athlete}
        />
      );
    });
  }

  render() {
    const athletes = this.getAthletes();
    const athleteContainerKey = this.props.isPlaceholder
      ? 'athlete-container-placeholder'
      : 'athlete-container';
    if (!athletes || !athletes.length) {
      return null;
    }
    return (
      <div aria-hidden={this.props.isPlaceholder ? true : null} className="suggested-athlete module">
        <h4 className="suggested-head">Suggested Athletes</h4>
        <ReactCSSTransitionReplace
          transitionName="fade-wait"
          transitionEnterTimeout={1000}
          transitionLeaveTimeout={1000}
        >
          <div key={athleteContainerKey}>{this.renderAthletes(athletes)}</div>
        </ReactCSSTransitionReplace>
      </div>
    );
  }
}

export default RecommendedAthletes;

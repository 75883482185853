/* eslint-disable max-len */
import React from 'react';

const MoreIcon = (props) => {
  return (
    <svg viewBox="0 0 19 5" className={props.className}>
      <g id="Library" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="01-Library---Name-Specific" transform="translate(-687.000000, -372.000000)" fill="#CBCBCB">
          <g id="VIDEO-GROUP" transform="translate(155.000000, 164.000000)">
            <g id="video-row">
              <g id="video-panel" transform="translate(290.000000, 45.000000)">
                <path d="M244.5,168 C245.880712,168 247,166.880712 247,165.5 C247,164.119288 245.880712,163 244.5,163 C243.119288,163 242,164.119288 242,165.5 C242,166.880712 243.119288,168 244.5,168 Z M251.5,168 C252.880712,168 254,166.880712 254,165.5 C254,164.119288 252.880712,163 251.5,163 C250.119288,163 249,164.119288 249,165.5 C249,166.880712 250.119288,168 251.5,168 Z M258.5,168 C259.880712,168 261,166.880712 261,165.5 C261,164.119288 259.880712,163 258.5,163 C257.119288,163 256,164.119288 256,165.5 C256,166.880712 257.119288,168 258.5,168 Z" id="icn-more"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

MoreIcon.propTypes = {
  className: React.PropTypes.string,
};

export default MoreIcon;

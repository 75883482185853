import React from 'react';
import g11n from 'hudl-g11n';

import { data, Service } from 'hudl-base';
import RecommendedAthletes from './recommended-athletes';
import RecommendedVideos from './recommended-videos';
import RecommendationsUtility from 'utility/recommendations-utility';
import UnloadUtility from 'utility/unload-utility';
import CommunityContentLogger from 'utility/community-content-logger';
import 'whatwg-fetch';

const DEFAULT_VISIBLE_RECOMMENDATION_COUNT = 7;
const IntlProvider = g11n.ReactIntl.IntlProvider;
const i18n = {
  locale: data.get('i18nLocale'),
  messages: data.get('i18nMessages'),
};

class Recommendations extends React.Component {
  static propTypes = {
    currentUserId: React.PropTypes.string,
    includeAthletes: React.PropTypes.bool,
    onRecommendedVideoVisible: React.PropTypes.func.isRequired,
    pageData: React.PropTypes.object.isRequired,
    sessionData: React.PropTypes.shape({
      uuid: React.PropTypes.string,
      usePostRollCta: React.PropTypes.bool,
    }),
    visibleRecommendationCount: React.PropTypes.number,
  };

  static defaultProps = {
    sessionData: {
      uuid: null,
      usePostRollCta: false,
    },
    visibleRecommendationCount: DEFAULT_VISIBLE_RECOMMENDATION_COUNT,
  };

  constructor(props) {
    super(props);
    this.handleAthleteClick = this.handleAthleteClick.bind(this);
    this.handleVideoClick = this.handleVideoClick.bind(this);
    this.handleMoreSuggestionsClick = this.handleMoreSuggestionsClick.bind(
      this,
    );

    // Commponent state
    this.state = {
      suggestionsCanBeExpanded: false,
      startTime: new Date().getTime(),
      recommendedVideosLoaded: false,
      recommendedAthletesLoaded: false,
    };
  }

  componentWillMount() {
    this.getVideoSuggestions();

    if (this.props.includeAthletes) {
      this.getAthleteSuggestions();
    }
  }

  getAthleteSuggestions() {
    new Service('relatedAthletes').get().then(results => {
      this.setState({ athletes: results, recommendedAthletesLoaded: true });
    });
  }

  getVideoSuggestions() {
    RecommendationsUtility.instance
      .getVideoSuggestions()
      .then(result => {
        const recommendations = result.recommendations || [];
        const videos = recommendations.map(video => {
          return {
            communityContentId: video.communityContentId,
            imageUrl: video.thumbnailUrl,
            owner: (video.ownerDetails && video.ownerDetails.name) || '',
            title: video.title,
            subtitle: video.subtitle,
            videoUrl: video.relativeUrl,
            views: video.views,
            reason: video.reason,
            badgeUrl: video.badgeUrl,
          };
        });

        this.setState({
          suggestionsCanBeExpanded:
            recommendations.length > DEFAULT_VISIBLE_RECOMMENDATION_COUNT,
          recommendedVideosLoaded: true,
          videos,
        });
      });
  }

  handleAthleteClick(athlete) {
    const timeToClick = new Date().getTime() - this.state.startTime;
    _gaq.push(['_trackEvent', 'SuggestionClicked', 'Athlete', athlete.url]);

    new Service('logSuggestionClicked').post({
      millis: timeToClick,
      ownerType: this.props.pageData.owner.ownerType,
      feedUserType: this.props.pageData.owner.feedUserType,
      sourceOwnerId: this.props.pageData.owner.id,
      sourceContentId: this.props.pageData.video.id,
      destinationSuggestionType: 'Athlete',
      suggestionId: athlete.id,
      destinationUrl: athlete.url,
      location: 'slot' + athlete.position,
      fromPostRoll: false,
      logData: {
        SessionId: this.props.sessionData.uuid,
        UsePostRollCta: this.props.sessionData.usePostRollCta,
      },
    });
  }

  handleVideoClick(video) {
    UnloadUtility.instance.setState({
      recommendationClicked: true,
    });

    CommunityContentLogger.recommendationClicked(
      video.communityContentId,
      video.reason,
      false,
    );
  }

  handleMoreSuggestionsClick(e) {
    e.preventDefault();
    this.setState({
      suggestionsCanBeExpanded: false,
    });

    new Service('logMoreSuggestionsClicked').post({});
  }

  getVideos() {
    const { visibleRecommendationCount } = this.props;
    const { videos = [] } = this.state;

    if (this.state.suggestionsCanBeExpanded) {
      return videos.slice(0, visibleRecommendationCount);
    }

    return [...videos];
  }

  render() {
    const videos = this.getVideos();
    const recommendedVideosIsPlaceholder = !this.state.recommendedVideosLoaded;
    const recommendedAthletesIsPlaceholder = !this.state.recommendedAthletesLoaded && this.props.includeAthletes;
    return (
      <IntlProvider {...i18n}>
        <div className="recommendation-design">
          <RecommendedVideos
            canBeExpanded={this.state.suggestionsCanBeExpanded}
            currentUserId={this.props.currentUserId}
            onMoreSuggestionsClick={this.handleMoreSuggestionsClick}
            onVideoVisible={this.props.onRecommendedVideoVisible}
            onVideoClick={recommendedVideosIsPlaceholder ? () => {} : this.handleVideoClick}
            isPlaceholder={recommendedVideosIsPlaceholder}
            videos={videos}
          />
          <RecommendedAthletes
            athletes={this.state.athletes}
            isPlaceholder={recommendedAthletesIsPlaceholder}
            onClick={recommendedAthletesIsPlaceholder ? () => {} : this.handleAthleteClick}
          />
        </div>
      </IntlProvider>
    );
  }
}

export default Recommendations;

import React from 'react';
import RecommendedVideo from './recommended-video';
import MoreIcon from 'components/shared/icon/more-icon';
import g11n from 'hudl-g11n';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import PlaceholderVideos from 'common/placeholders/recommended-videos';

const FormattedMessage = g11n.ReactIntl.FormattedMessage;

class RecommendedVideos extends React.Component {
  static propTypes = {
    canBeExpanded: React.PropTypes.bool,
    currentUserId: React.PropTypes.string,
    isPlaceholder: React.PropTypes.bool.isRequired,
    onVideoClick: React.PropTypes.func.isRequired,
    onVideoVisible: React.PropTypes.func.isRequired,
    onMoreSuggestionsClick: React.PropTypes.func.isRequired,
    videos: React.PropTypes.array,
  };

  static defaultProps = {
    videos: [],
  };

  render() {
    const videos = this.props.isPlaceholder
      ? PlaceholderVideos
      : this.props.videos;
    if (!videos || !videos.length) {
      return null;
    }

    const videoContainerKey = this.props.isPlaceholder
      ? 'video-container-placeholder'
      : 'video-container';
    return (
      <div className="module">
        <h4 className="suggested-head">
          <FormattedMessage id="profiles.video.recommendations_label" />
        </h4>
        <ReactCSSTransitionReplace
          transitionName="fade-wait"
          transitionEnterTimeout={1000}
          transitionLeaveTimeout={1000}
        >
          <div key={videoContainerKey}>
            {videos.map((videoProps, index) => {
              return (
                <RecommendedVideo
                  key={`recommended-video-${index}`}
                  isPlaceholder={videoProps.isPlaceholder}
                  placeholderTitles={videoProps.placeholderTitles}
                  currentUserId={this.props.currentUserId}
                  onClick={this.props.onVideoClick}
                  onVisible={this.props.onVideoVisible}
                  position={index + 1}
                  {...videoProps}
                />
              );
            })}
          </div>
        </ReactCSSTransitionReplace>
        {this.props.canBeExpanded ? (
          <div className="suggested-video-footer">
            <a href="#" onClick={this.props.onMoreSuggestionsClick}>
              <MoreIcon className="icn-more more-suggestions-icon" />
            </a>
          </div>
        ) : null}
      </div>
    );
  }
}

export default RecommendedVideos;
